import React, { createContext, useCallback, useContext, useState } from 'react';

import api from '../services/api';
import { Usuario } from '../database/Usuario';

interface AuthState {
  token: string;
  usuario: object;
}

interface SignInCredentials {
  email: string;
  senha: string;
}

interface AuthContextData {
  usuario: object;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUsuario(usuario: Usuario):void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {  
    const token = localStorage.getItem('@DesafioDatasis:token');
    const usuario = localStorage.getItem('@DesafioDatasis:usuario');

    if (token && usuario) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      
      return { token, usuario: JSON.parse(usuario) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post('token', {
      email,
      senha,
    });

    const { token, usuario } = response.data;

    localStorage.setItem('@DesafioDatasis:token', token);
    localStorage.setItem('@DesafioDatasis:usuario', JSON.stringify(usuario));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, usuario });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@DesafioDatasis:token');
    localStorage.removeItem('@DesafioDatasis:usuario');

    setData({} as AuthState);
  }, []);

  const updateUsuario = useCallback(
    (usuario: Usuario) => {
      localStorage.setItem('@DesafioDatasis:usuario', JSON.stringify(usuario));

      setData({
        token: data.token,
        usuario,
      });
    },
    [data.token],
  );

  return (
    <AuthContext.Provider value={{ usuario: data.usuario, signIn, signOut, updateUsuario }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
