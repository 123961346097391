import Dexie, { Table } from "dexie";
import { Usuario } from "./Usuario";
import { ToDo } from "./ToDo";

export class myEstoque  extends Dexie {
  usuario!: Table<Usuario>;
  todo!: Table<ToDo>;

  constructor() {
    super('estoque');
    this.version(1).stores({
      usuario: '++id, nome, senha, email, cpf, ativo, tipo, dt_criacao, dt_alteracao',
      todo: '++id, descricao, ativo, usuario, dt_criacao, dt_alteracao',
    });
  }
}

export const db = new myEstoque();
