import React from 'react';
import { Typography, Button, Avatar, IconButton } from '@mui/material';
import { Link  } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { StyledAppBar, StyledToolbar } from './styles';
import { FiArrowLeft, FiArrowRight, FiUserCheck } from 'react-icons/fi';

const Navbar: React.FC = () => {
  const {  signOut } = useAuth();
  return (
    <StyledAppBar>
      <StyledToolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
         Datasis tecnologia
        </Typography>
        <Button color="secondary" component={Link} to="/dashboard"><FiArrowLeft/>Dashboard</Button>
        <Button color="primary" onClick={signOut}>Sair<FiArrowRight /></Button>

        <IconButton
              aria-label="show more"
              aria-controls={"primary-search-account-menu"}
              aria-haspopup="true"
              color="success"
              disabled={false}
            >
              <FiUserCheck />
           </IconButton>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Navbar;
