import { Background, Container, Content, Titulo } from './styles';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { FiLock, FiLogIn, FiMail } from 'react-icons/fi';
import axios from 'axios';
interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const zoomLevel = window.innerWidth / document.documentElement.clientWidth;
      setScale(zoomLevel);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          senha: data.password,
        });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          console.error(errors);

          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description: errors.email || errors.password,
          });

          return;
        } else if (axios.isAxiosError(err)) {
          if (err.response?.data?.message) {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: `Erro: ${err.response.data.message || 'Erro no servidor'}`,
            });
          } else if (err.request) {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: 'Nenhuma resposta do servidor, verifique sua conexão.',
            });
          } else {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: `Erro na configuração da requisição: ${err.message}`,
            });
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Container scale={scale} >
      <Content>
        <Titulo>Faça seu login</Titulo>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="email" icon={FiMail} placeholder="E-mail" />

          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />

          <div className="form-group">
            <Button type="submit">Entrar</Button>
          </div>
        </Form>
        <Link to="/sign-up">
            <FiLogIn />
            Criar conta
        </Link>
      </Content>
      <Background />
    </Container>
  );
}

export default SignIn;

