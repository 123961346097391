import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  background: #f0f0f5;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

`;

export const Content = styled.div`
  width: 100%;
  max-width: 800px;
  background: #f0f0f5;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);

  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 16px;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background: #ad2710 ;
      color: white;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background: #ad273f;
      }
    }
  }
`;

export const Grade = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  list-style: none;
  margin-top: 20px;

  li {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    button {
      position: absolute;
      right: 24px;
      top: 24px;
      border: 0;
      background: none;
      cursor: pointer;

      svg {
        color: #a8a8b3;
      }

      &:hover {
        svg {
          color: #e02041;
        }
      }
    }

    strong {
      display: block;
      margin-bottom: 8px;
      color: #41414d;
    }

    p {
      color: #737380;
      line-height: 21px;
      font-size: 16px;
    }

    p + strong {
      margin-top: 2px;
    }
  }
`;
