import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Dashboard from '../pages/Dashboard';
import Usuario from '../pages/Usuario';
import SignIn from '../pages/SignIn';
import ToDo from '../pages/ToDo';
import SignUp from '../pages/SignUp';
import Error404 from '../pages/Error404';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/usuarios" component={Usuario} isPrivate />
      <Route path="/todos" component={ToDo} isPrivate />
      <Route path="*" component={Error404} isPrivate />
    </Switch>
  );
};

export default Routes;
