import React, { useCallback, useRef, useEffect } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Box, Button } from '@mui/material';

import { db } from '../../database/db';
import Input from '../../components/Input';
import Navbar from '../../components/Navbar';
import { Container, Content, Grade } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Usuario as UsuarioDTO } from '../../database/Usuario';
import { FiTrash2 } from 'react-icons/fi';

interface UsuarioFormData {
  nome: string;
  email: string;
  senha: string;
  confirmSenha: string;
  cpf: string;
}

const Usuario: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const usuarios = useLiveQuery(() => db.usuario.toArray());
  const token = localStorage.getItem('@DesafioDatasis:token');
  const userId = localStorage.getItem('@DesafioDatasis:usuario') && JSON.parse(localStorage.getItem('@DesafioDatasis:usuario') || '').id;
  const { addToast } = useToast();

  const handleAddUsuario = useCallback(async (data: UsuarioFormData) => {
    try {
      // formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório')
                 .min(3, 'Nome deve ter pelo menos 3 digitos')
                 .max(150, 'Nome deve ter no maximo 150 digitos'),
        email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
        senha: Yup.string().required('Senha obrigatória')
                  .min(6, 'Senha deve ter pelo menos 6 digitos'),
        confirmSenha: Yup.string()
          .oneOf([Yup.ref('senha')], 'As senhas precisam ser iguais')
          .required('Confirmação de senha obrigatória')
          .min(6, 'Senha deve ter pelo menos 6 digitos'),
        cpf: Yup.string().required('CPF obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const dataJSON = {
        nome: data.nome,
        email: data.email,
        senha: data.senha,
        cpf: data.cpf
      }

      const response =  await api.post('/usuarios', dataJSON, {
        headers: {
          Authorization: `Bearer ` + token,
        }
      });

      const usuarioNovo: UsuarioDTO = response.data;

      const id = await db.usuario.add({
        nome: usuarioNovo.nome,
        senha: usuarioNovo.senha,
        email: usuarioNovo.email,
        cpf: usuarioNovo.cpf,
        ativo: usuarioNovo.ativo,
        tipo: usuarioNovo.tipo,
        dt_criacao: new Date().toISOString(),
        dt_alteracao: new Date().toISOString(),
      });

      addToast({
        type: 'success',
        title: 'Usuário criado',
        description: `ID: ${id}, Nome: ${data.nome} criado com sucesso.`,
      });

      if (formRef.current) {
        formRef.current.reset();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        console.error(errors);

        addToast({
          type: 'info',
          title: 'Informação na inserir usuario',
          description: errors.nome || errors.email|| errors.senha|| errors.cpf||errors.confirmSenha || 'Ocorreu um erro ao inserir o usuario.',
        });

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na criação',
        description: 'Ocorreu um erro ao criar o usuário, tente novamente.',
      });
    }
  }, [addToast]);

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchAndAddUsuarios = async () => {
      try {
        const response = await api.get('usuarios', {
          headers: {
            Authorization: `Bearer ` + token,
          },
        });

        db.usuario.clear();

        const usuariosData: UsuarioDTO[] = response.data;

        for (const usuario of usuariosData) {
          await addUsuarios(usuario);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('Failed to fetch to-dos:', error.message);
        } else {
          console.error('Failed to fetch to-dos:', error);
        }
      }
    };

    fetchAndAddUsuarios();
  }, [token]);


  async function handleDeleteToDo(id: number| undefined) {
    try {
      if (navigator.onLine) {
        await api.delete(`/usuarios/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      await db.usuario.delete(id);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuário deletado com sucesso.',
      });
    } catch (err) {
      alert('Erro ao deletar caso, tente novamente.');
    }
  }

  const addUsuarios = async (data: UsuarioDTO) => {
    try {
      const { id , nome, email, senha, cpf, ativo, tipo, dt_criacao, dt_alteracao} = data;

       await db.usuario.add({
        id,
        nome,
        email,
        senha,
        cpf,
        ativo,
        tipo,
        dt_criacao,
        dt_alteracao,
      });
    } catch (error) {
      if (error instanceof Error) {
        alert(`Failed to add usuario: ${error.message}`);
      } else {
        alert('Failed to add usuario: An unknown error occurred.');
      }
    }
  };

  if (!usuarios) {
    return null;
  }

  return (
    <Container>
      <Navbar />
      <Content>
        <Form ref={formRef} onSubmit={handleAddUsuario}>
          <h2>Usuário</h2>
          <Input placeholder="Nome" name="nome" />
          <Input placeholder="Email" name="email"  />
          <Input placeholder="Senha" name="senha" type="password"  />
          <Input placeholder="Confirmar Senha" type="password" name="confirmSenha" />
          <Input placeholder="CPF" name="cpf"/>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </Form>
      </Content>
      <Box>
        <Grade>
          <ul>
            <li>Lista de usuários</li>
            {usuarios?.map(usuario => (
              <li key={usuario.id}>
                <p>Código: {usuario.id}, Nome: {usuario.nome}, Email: {usuario.email}, CPF: {usuario.cpf},{(usuario.ativo === 'A' ? '' : ' Status: Inativo,' )} Criado:{usuario.dt_criacao ? new Date(usuario.dt_criacao).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : ''} Alterado:{usuario.dt_alteracao ? new Date(usuario.dt_alteracao).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : ''}</p>
                {/* <button style={usuario.id === userId ? { display: 'none' } : {}} onClick={() => handleDeleteToDo(usuario.id)} type="button" >
                      <FiTrash2 size={20} color="#a8a8b3" />
                </button> */}
              </li>
            ))}
          </ul>
        </Grade>
      </Box>
    </Container>
  );
};

export default Usuario;
