import React from "react";
import { Container, HomeLink, Subtitle, Title } from "./styles";


const Error404: React.FC = () => {
  return (
    <Container>
      <Title>404</Title>
      <Subtitle>Página não encontrada</Subtitle>

      <HomeLink href="/">Voltar para o início</HomeLink>
    </Container>
  );
};

export default Error404;
