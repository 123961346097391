import styled from "styled-components";

export const StyledAppBar  = styled.div`
  position: static;
`;

export const StyledToolbar  = styled.div`
    display: flex;
    justify-content: space-between;
`;
