import styled, { keyframes } from 'styled-components';
import toDoList from '../../assets/ToDoList.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
      color: #3a3a3a;
    }

    /* input {
      background: #f4ede8;
      border-radius: 10px;
      border: 2px solid #f4ede8;
      padding: 16px;
      width: 100%;
      color: #3a3a3a;
      display: flex;
      align-items: center;
      margin-top: 8px;

      & + input {
        margin-top: 8px;
      }
    } */

    button {
      background: #e02041;
      height: 56px;
      border-radius: 10px;
      border: 0;
      padding: 0 16px;
      color: #fff;
      width: 100%;
      font-weight: 500;
      margin-top: 16px;
      transition: background-color 0.2s;
    }

    a {
      color: #e02041;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      svg {
        margin-right: 8px;
      }
    }
  }

  > a {
    color: #e02041;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }

  @media (max-width: 340px) {
    form {
      width: 100%;
      padding: 0 20px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${toDoList}) no-repeat center;
  background-size: cover;
`;
