import styled from "styled-components";

import toDoList from '../../assets/Dashboard.svg';

const breakpoints = {
  large: '1200px',
  medium: '992px',
  small: '758px',
  xsmall: '620px',
};

export const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.header`
  padding: 40px 0;
  background:  #F2F3F5;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 80px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${toDoList}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 80vh;
  transition: background-size 0.3s ease;

  @media (max-width: ${breakpoints.large}) {
    background-attachment: scroll;
    background-position: center;
  }

  @media (max-width: ${breakpoints.medium}) {
    background-attachment: scroll;
    background-position: top;
  }

  @media (max-width: ${breakpoints.small}) {
    background-attachment: scroll;
    background-position: top;
    background-size: contain;
  }

  @media (max-width: ${breakpoints.xsmall}) {
    background-attachment: scroll;
    background-position: top;
    background-size: cover;
  }
`;

export const NavButton = styled.button`
  @media (max-width: ${breakpoints.xsmall}) {
    span {
      display: none;
    }
  }
`;

