import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  margin-top: 50px;
`;

export const Title = styled.h1`
  font-size: 72px;
  margin: 0;
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  margin: 10px 0;
`;

export const HomeLink = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ce2102;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ce2102;
  }
`;
