import styled from 'styled-components';

export const Container = styled.button`
  background: #e02041;
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #ffffff;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
`;

export const InputGroup = styled.div`
      display: flex;
      gap: 0.25rem;
      align-items: center;

      background: #fff;
      font-size: 16px;

      input {
        flex: 1;
        background: var(--background);
        border: 0;
        color: var(--text);
        padding: 12px 24px;

        border-radius: 8px;
        border: 0;

        &::placeholder {
          color: var(--text-light)
        }
      }
`;
