import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  $size?: string;
}

export const Container = styled.div<ContainerProps>`
  background: #dcdce6;
  border-radius: 10px;
  padding: 14px;
  width: 100%;

  border: 2px solid #c53030;
  color: #dcdce6;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #ce2102;
      border-color: #ce2102;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ce2102;
    `}

    input {
      flex: 1;
      background: transparent;
      border: 0;
      color:  #000000;
      margin: ${({ $size }) => $size || '1em'}; /* Ajustando o estilo de margem */
      padding: ${({ $size }) => $size || '1em'}; /* Ajustando o estilo de preenchimento */

      &::placeholder {
        color: #666360;
      }
    }


  svg {
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    padding: 0.75rem;
    input {
      font-size: 0.875rem;
    }
    svg {
      margin-right: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    input {
      font-size: 0.75rem;
    }
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }

  @media (max-width: 768px) {
    margin-left: 0.75rem;
    span {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 480px) {
    margin-left: 0.5rem;
    span {
      font-size: 0.75rem;
    }
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;
