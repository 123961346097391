import * as Yup from 'yup';
import React, { useCallback, useEffect, useRef } from "react";
import { FiCheckSquare, FiTrash2 } from "react-icons/fi";
import { Container, Content, Grade } from "./styles";
import Input from "../../components/Input";
import { FormHandles } from "@unform/core";
import { db } from "../../database/db";
import { useLiveQuery } from "dexie-react-hooks";
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import { ToDo } from '../../database/ToDo';
import Navbar from '../../components/Navbar';
import { Box } from '@mui/material';
import { Form as StyledForm, InputGroup, Button as StyledButton } from './styles';

interface TodoFormData {
  descricao: string;
}

const ToDoList: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const todos = useLiveQuery(() => db.todo.toArray(), []);
  const token = localStorage.getItem('@DesafioDatasis:token');
  const userId = localStorage.getItem('@DesafioDatasis:usuario') && JSON.parse(localStorage.getItem('@DesafioDatasis:usuario') || '').id;
  const { addToast } = useToast();

  const handleNewToDos = useCallback(
    async (data: TodoFormData) => {
      try {
        const schema = Yup.object().shape({
          descricao: Yup.string().required('Descrição obrigatório')
                 .min(3, 'Descrição deve ter pelo menos 3 digitos')
                 .max(250, 'Descrição deve ter no maximo 250 digitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dataJSON = { descricao: data.descricao };
        const newToDo: ToDo = {
          descricao: data.descricao,
          ativo: 'A',
          usuario: userId?.toString() || 0, // Atualize conforme necessário
          dt_criacao: new Date().toISOString(),
          dt_alteracao: new Date().toISOString(),
        };

        if (navigator.onLine) {
          const response = await api.post('/todos', dataJSON, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          newToDo.id = response.data.id;
          await db.todo.add(newToDo);
        } else {
          await db.todo.add(newToDo);
          addToast({
            type: 'info',
            title: 'Modo Offline',
            description: 'O ToDo será sincronizado quando estiver online.',
          });
        }
        addToast({
          type: 'success',
          title: 'Sucesso a inserir to-do',
          description: 'To-do inserido com sucesso.',
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        console.error(errors);

        addToast({
          type: 'info',
          title: 'Informação na inserir to-do',
          description: errors.descricao || 'Ocorreu um erro ao inserir o to-do.',
        });

        return;
      }
    }

    if (formRef.current) {
      formRef.current.reset();
    }
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }

    const fetchAndAddToDos = async () => {
      try {
        if (navigator.onLine) {
          const response = await api.get('/todos', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          db.todo.clear();
          const toDosData: ToDo[] = response.data;
          await db.todo.bulkAdd(toDosData);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('Failed to fetch to-dos:', error.message);
        } else {
          console.error('Failed to fetch to-dos:', error);
        }
      }
    };

    fetchAndAddToDos();
  }, [token]);

  async function handleDeleteToDo(id: number| undefined) {
    try {
      if (navigator.onLine) {
        await api.delete(`/todos/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      await db.todo.delete(id);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'ToDo deletado com sucesso.',
      });
    } catch (err) {
      alert('Erro ao deletar caso, tente novamente.');
    }
  }

  async function handleToggleToDoCompletion(id: number | undefined) {
     try {
      let data = await db.todo.get(id);
      if (!data) return;

      const updatedToDo = {
        ...data,
        ativo: data.ativo === 'A' ? 'I' : 'A',
        dt_alteracao: new Date().toISOString(),
      };

      if (navigator.onLine) {
        await api.put(`/todos/${id}`, updatedToDo, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      await db.todo.update(id, { ativo: updatedToDo.ativo, dt_alteracao: updatedToDo.dt_alteracao });
      addToast({
        type: 'info',
        title: 'Alterado',
        description: 'ToDo alterado com sucesso.',
      });
    } catch (err) {
      alert('Erro ao deletar caso, tente novamente.')
    }
  }

  if (!todos) {
    return null;
  }

  return (
    <Container>
      <Navbar />
      <Content>
        <StyledForm ref={formRef} onSubmit={handleNewToDos}>
          <h2>Tarefas</h2>
          <InputGroup>
            <Input placeholder="Adicionar nova tarefa" name="descricao" />
            <div>
              <StyledButton className="button" type="submit">
                <FiCheckSquare size={32} color="#fff"/>
              </StyledButton>
            </div>
          </InputGroup>
        </StyledForm>
      </Content>
      <Box>
        <Grade>
            {todos?.map(todo => (
              <li key={todo.id}>
                <div className={todo.ativo === 'I' ? 'completed' : ''} data-testid="task" >
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      readOnly
                      checked={todo.ativo === 'I'}
                      onClick={() => handleToggleToDoCompletion(todo.id)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p>Descrição: {todo.descricao}</p>
                  <strong>Id: {todo.id} Usuário: {todo.usuario} Criado: {todo.dt_criacao ? new Date(todo.dt_criacao).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : ''} Alterado: {todo.dt_alteracao ? new Date(todo.dt_alteracao).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : ''} </strong>
                </div>
                <Box gridAutoColumns={1}>
                    <button style={todo.ativo === 'I' ? { display: 'none' } : {}} onClick={() => handleDeleteToDo(todo.id)} type="button" >
                      <FiTrash2 size={20} color="#a8a8b3" />
                    </button>
                </Box>
              </li>
            ))}
          </Grade>
      </Box>
    </Container>
  )
}
export default ToDoList;
