// src/serviceWorkerRegistration.ts

// Este arquivo é uma implementação do serviço worker de create-react-app.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] é o endereço localhost IPv6.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 são considerados como IPv4 localhost para convenções de rede.
  window.location.hostname.match(
    /^127(?:\.[0-9]+){0,2}\.[0-9]+$/
  )
);

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // O construtor do URL é avaliado para os URL relativos como, por exemplo, /service-worker.js.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Nosso service worker não funcionará se PUBLIC_URL estiver em um origem diferente do nosso site.
      // Isso ocorre se estiver usando um CDN para servir os recursos; veja https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // Isso está rodando no localhost. Vamos verificar se um service worker ainda existe ou não.
        checkValidServiceWorker(swUrl, config);

        // Adicione um log adicional ao localhost, apontando aos desenvolvedores que estão usando o service worker.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'Este aplicativo está sendo servido no cache-first por um service worker. ' +
              'Para saber mais, visite https://bit.ly/CRA-PWA'
          );
        });
      } else {
        // Não é localhost. Basta registrar o service worker.
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // No ponto, o conteúdo antigo foi purgado e o novo conteúdo
              // está sendo servido no cache-first.
              console.log(
                'Novo conteúdo está disponível e será usado quando todas ' +
                  'as guias estiverem fechadas.'
              );

              // Executar o callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // No ponto, tudo foi pré-carregado em cache.
              // O conteúdo está disponível offline.
              console.log('Conteúdo está cacheado para uso offline.');

              // Executar o callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Erro durante o registro do service worker:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Verifique se o service worker pode ser encontrado. Se não puder recarregar a página.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Certifique-se de que o service worker existe e que realmente estamos recebendo um arquivo JavaScript.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // Nenhum service worker foi encontrado. Provavelmente, uma app diferente. Atualize a página.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker foi encontrado. Prossiga normalmente.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'Nenhuma conexão com a Internet encontrada. O aplicativo está rodando no modo offline.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}

interface Config {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
}
