import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiUser, FiList } from "react-icons/fi";
import { Background, Container, Header, HeaderContent } from "./styles";
import { AppBar, Typography, Button, Container as MuiContainer, Box } from '@mui/material';
import { useAuth } from "../../hooks/auth";

const Dashboard: React.FC = () => {
    const { signOut } = useAuth();
    return (
      <Container>
        <Header>
          <AppBar position="static" color="transparent" elevation={0}>
            <HeaderContent>
              <Box display="flex" alignItems="center">
                <Button color="inherit" component={Link} to="/usuarios" startIcon={<FiUser />}>
                  Usuários
                </Button>
                <Button color="inherit" component={Link} to="/todos" startIcon={<FiList />}>
                  To-Do
                </Button>
              </Box>
              <Button color="inherit" onClick={signOut} startIcon={<FiArrowRight />}>
                Sair
              </Button>
            </HeaderContent>
          </AppBar>
        </Header>
        <MuiContainer>
          <Box my={4}>
            <Typography variant="h4" component="h1" gutterBottom>
             <Background />
            </Typography>
          </Box>
        </MuiContainer>
      </Container>
    )
};

export default Dashboard;
