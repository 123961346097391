import React, { useCallback, useRef } from 'react';
import { FiArrowLeft, FiUser, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';

interface SignUpFormData {
  nome: string;
  email: string;
  senha: string;
  confirmSenha: string;
  cpf: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório')
                   .min(3, 'Nome deve ter pelo menos 3 digitos')
                   .max(150, 'Nome deve ter no maximo 150 digitos'),
          email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
          senha: Yup.string().required('Senha obrigatória')
                    .min(6, 'Senha deve ter pelo menos 6 digitos'),
          confirmSenha: Yup.string()
            .oneOf([Yup.ref('senha')], 'As senhas precisam ser iguais')
            .required('Confirmação de senha obrigatória')
            .min(6, 'Senha deve ter pelo menos 6 digitos'),
          cpf: Yup.string().required('CPF obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.senha !== data.confirmSenha) {
          addToast({
            type: 'error',
            title: 'Erro na inserir senha',
            description: 'As senhas precisam ser iguais',
          });
          return;
        }

        await api.post('/usuarios/criar', {
          nome: data.nome,
          email: data.email,
          senha: data.senha,
          cpf: data.cpf
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Você já pode fazer seu login no to-do List!',
        });

        if (formRef.current) {
          formRef.current.reset();
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          console.error(errors);

          addToast({
            type: 'info',
            title: 'Informação na inserir usuario',
            description: errors.nome || errors.email|| errors.senha|| errors.cpf||errors.confirmSenha || 'Ocorreu um erro ao inserir o usuario.',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu cadastro</h1>

            <Input icon={FiUser} placeholder="Nome" name="nome" />

            <Input icon={FiMail} placeholder="E-mail" name="email"  />

            <Input icon={FiLock} placeholder="Senha" name="senha" type="password"  />

            <Input icon={FiLock} placeholder="Confirmar Senha" type="password" name="confirmSenha" />

            <Input placeholder="CPF" name="cpf"/>

            <Button type="submit">Cadastrar</Button>
          </Form>

          <Link to="/">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
