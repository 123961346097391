import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --font-primary: 'Roboto', serif;
    --color-background: #f0f5f5;
    --color-text: #000000;
    --color-primary: #ce2102;
    --font-size-base: 18px;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
  }

  body {
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-primary);
    font-size: var(--font-size-base);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, textarea {
    font-family: var(--font-primary);
    font-size: 1rem; /* Usando unidades relativas */
    font-weight: var(--font-weight-regular);
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: var(--font-weight-medium);
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    font: inherit;
  }
  a {
    text-decoration: none;
    color: inherit;
    transition: color 0.2s;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 1200px) {
    html {
      font-size: 93.75%; /* 15px */
    }
  }

  @media (max-width: 992px) {
    html {
      font-size: 87.5%; /* 14px */
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 81.25%; /* 13px */
    }
  }

  @media (max-width: 480px) {
    html {
      font-size: 75%; /* 12px */
    }
  }
`;
