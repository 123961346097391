import styled from "styled-components";
import { Form as Unform } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  background: #f0f0f5;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;

  form {
    button {
      margin-top: 16px;
      padding: 10px 15px;
      border: none;
      border-radius: 4px;
      background:#ad2710;
      color: white;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background: #ad273f;
      }
    }
  }
`;

export const Header = styled.header`
  padding: 120px 0;
  background: #000000;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 80px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Grade = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5S00px, 1fr));
  grid-gap: 24px;
  list-style: none;
  margin-top: 20px;

  li {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    button {
      position: absolute;
      right: 24px;
      top: 24px;
      border: 0;
      background: none;
      cursor: pointer;

      svg {
        color: #a8a8b3;
      }

      &:hover {
        svg {
          color: #e02041;
        }
      }
    }

    strong {
      display: block;
      margin-bottom: 8px;
      color: #41414d;
    }

    p {
      color: #737380;
      line-height: 21px;
      font-size: 16px;
    }

    p + strong {
      margin-top: 2px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 14px;

      p {
        font-size: 1rem;
        color: var(--text);
      }

      &.completed {
        p {
          text-decoration: line-through;
          opacity: 0.6;
        }
      }

      .checkbox-container {
        display: block;
        position: relative;
        padding-left: 24px;
        margin-bottom: 18px;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked {
            & ~ .checkmark {
              background-color: #ad273f;
            }

            & ~ .checkmark:after {
              display: block;
            }
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          width: 16px;
          height: 16px;
          background-color: #EBEBEB;
          border-radius: 2px;

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 3px;
            height: 6px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  h2 {
    margin-bottom: 20px;
    color: #333;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin: 0;

  input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #ad273f;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ad273f;
  }

  .button {
    display: flex;
    align-items: center;
  }
`;
