import axios from "axios";
import { configDotenv } from "dotenv";

configDotenv({ path: ".env" });

const api = axios.create({
    baseURL: `${process.env.REACT_APP_ENDERECO_API}`,
})

export default api;
